import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useContext } from "react";
import { fetchGet, fetchPost, LayoutContext } from "wcz-layout";
import CreateFeedbackDto from "../models/CreateFeedbackDto";
import Event from "../models/Event";
import { apiUrl } from "../utils/BaseUrl";

const queryKey: string = "api";

export function useGetEvents<TQueryFnData = Event[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([queryKey], ({ signal }) => fetchGet(`${apiUrl}/${queryKey}/events`, signal), options);
}

interface UseCreateOptions {
    onSuccess?: (data: CreateFeedbackDto) => void,
    onError?: (message: string) => void,
}

export function useCreateFeedback(options?: UseCreateOptions) {
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: CreateFeedbackDto) => fetchPost(`${apiUrl}/${queryKey}/feedbacks`, model), {
        onError: (err: string) => {
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: (model) => {
            if (options?.onSuccess)
                options.onSuccess(model);
        },
    });
}