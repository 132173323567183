import { Home } from "@mui/icons-material";
import { indigo, purple } from "@mui/material/colors";
import { useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LayoutProvider, LeftDrawerItem, useUser } from "wcz-layout";
import packageJson from "../package.json";
import { HomePage } from "./pages/HomePage";
import SurveyPage from "./pages/SurveyPage";

export const App: React.FC = () => {
    const user = useUser();

    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Layout.Home", path: "/", icon: <Home fontSize="large" /> },
    ], [user]);

    return (
        <BrowserRouter>
            <LayoutProvider title="Training Feedbacks" appVersion={packageJson.version} primaryColor={indigo[500]} secondaryColor={purple[500]} leftDrawerItems={leftDrawerItems}>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/survey' element={<SurveyPage />} />
                </Routes>
            </LayoutProvider>
        </BrowserRouter>
    );
};