import { isDevelopment } from "wcz-layout";

let apiUrl = "https://api.dev.wistron.eu/noauth/ts-feedback";
let peoplesoftUrl = "https://api.dev.wistron.eu/ps";
let feedbackServiceUrl = "http://mcs-ts-feedback.qas.wcz.wistron/api";

if (!isDevelopment) {
    apiUrl = "https://api.wistron.eu/noauth/ts-feedback";
    peoplesoftUrl = "https://api.wistron.eu/ps";
    feedbackServiceUrl = "http://mcs-ts-feedback.wcz.wistron/api";
}

export { apiUrl, peoplesoftUrl, feedbackServiceUrl }