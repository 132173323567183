import { AccountCircle, Send, StarBorder } from '@mui/icons-material';
import { Box, Button, Container, Grid, InputAdornment, Rating, TextField, Typography } from '@mui/material';
import { Fragment, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LayoutContext } from 'wcz-layout';
import Answer from '../models/Answer';
import { useCreateFeedback } from '../queries/FeedbackQueries';

const initialData: Answer[] = [
    {
        OrderNumber: 1,
        QuestionTextCz: "Školení bylo dobře zorganizované.",
        QuestionTextEn: "The training was well organized.",
        AnswerType: "Rating",
        Value: ""
    },
    {
        OrderNumber: 2,
        QuestionTextCz: "Výklad byl srozumitelný.",
        QuestionTextEn: "The interpretation was understandable.",
        AnswerType: "Rating",
        Value: ""
    },
    {
        OrderNumber: 3,
        QuestionTextCz: "Školitel/ka je na dané téma odborník.",
        QuestionTextEn: "The trainer is professional.",
        AnswerType: "Rating",
        Value: ""
    },
    {
        OrderNumber: 4,
        QuestionTextCz: "Obsah školení pro mě byl přínosný.",
        QuestionTextEn: "The content of the training was valuable for me.",
        AnswerType: "Rating",
        Value: ""
    },
    {
        OrderNumber: 5,
        QuestionTextCz: "Prostředí školení bylo vyhovující.",
        QuestionTextEn: "The training environment was satisfactory.",
        AnswerType: "Rating",
        Value: ""
    },
    {
        OrderNumber: 6,
        QuestionTextCz: "Celkově pro mě bylo školení přínosné.",
        QuestionTextEn: "Overall, this course was valuable for me.",
        AnswerType: "Rating",
        Value: ""
    },
    {
        OrderNumber: 7,
        QuestionTextCz: "Zde je prostor pro další zpětnou vazbu či komentář ke školení.",
        QuestionTextEn: "Please share any other feedback or comment with us.",
        AnswerType: "Comment",
        Value: ""
    }
];

export default function SurveyPage() {
    const { snackbar, t, i18n } = useContext(LayoutContext);
    const [employeeId, setEmployeeId] = useState("");
    const [answers, setAnswers] = useState(initialData);
    const [searchParams] = useSearchParams();

    const id = searchParams.get("id");
    const subId = searchParams.get("subid");

    const updateAnswerValue = (orderNumber: number, value: string | number | null) => {
        let newValue = value ? value.toString() : "";

        let newArr: Answer[] = answers.map((a) => {
            return a.OrderNumber === orderNumber ? { ...a, Value: newValue } : a;
        });

        setAnswers(newArr)
    };

    const { mutate } = useCreateFeedback({
        onSuccess: () => {
            setEmployeeId("");
            setAnswers(initialData);
            snackbar({ message: "Děkujeme za Vaší zpětnou vazbu! :-)" })
        }
    });

    const submit = () => mutate({
        EventId: id ? id.toString() : "",
        EventSubId: subId ? Number(subId) : 0,
        AttendeeId: employeeId,
        AttendeeName: "Anonymous",
        Answers: answers,
        CreatedBy: "system"
    });

    return (
        <Container sx={{ my: 4 }} maxWidth="xl">
            <Grid container sx={{ mb: 2 }}>
                <Grid item xs={10} md={10} lg={6} xl={4}>
                    <TextField
                        label={t("LabelEmployeeId")}
                        fullWidth
                        value={employeeId}
                        onChange={(event: any) => setEmployeeId(event.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><AccountCircle /></InputAdornment>
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 2 }}>
                {answers.map(answer =>
                    <Grid item xs={12} key={answer.OrderNumber}>
                        {answer.AnswerType === "Rating" &&
                            <Fragment>
                                <Typography component="legend" sx={{ fontSize: "2vh", fontWeight: 700 }}>{answer.OrderNumber}. {i18n.resolvedLanguage === "cs" ? answer.QuestionTextCz : answer.QuestionTextEn}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ ml: 2 }}>{t("LabelDisagree")}</Box>
                                    <Rating
                                        name={"rating-" + answer.OrderNumber}
                                        value={Number(answer.Value)}
                                        emptyIcon={<StarBorder fontSize="inherit" />}
                                        onChange={(event, newValue) => updateAnswerValue(answer.OrderNumber, newValue)}
                                        sx={{ fontSize: "5vh" }}
                                    />
                                    <Box sx={{ ml: 2 }}>{t("LabelAgree")}</Box>
                                </Box>
                            </Fragment>
                        }

                        {answer.AnswerType === "Comment" &&
                            <Fragment>
                                <Typography component="legend" sx={{ fontSize: "2vh", fontWeight: 700 }}>{answer.OrderNumber}. {i18n.resolvedLanguage === "cs" ? answer.QuestionTextCz : answer.QuestionTextEn}</Typography>
                                <TextField
                                    multiline
                                    rows={4}
                                    fullWidth
                                    value={answer.Value}
                                    placeholder={t("PlaceholderComment")}
                                    variant="outlined"
                                    onChange={event => updateAnswerValue(answer.OrderNumber, event.target.value)}
                                />
                            </Fragment>
                        }
                    </Grid>
                )}
            </Grid>

            <Button variant="contained" color="primary" sx={{ fontSize: "2.3vh" }} endIcon={< Send />} onClick={submit}>{t("SubmitButtonText")}</Button>
        </Container>
    );
}